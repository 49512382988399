import { TypographyOptions } from "@mui/material/styles/createTypography";
import { fonts, typography1 } from "./variables";

const typography: TypographyOptions = {
  fontFamily: [
    fonts.baseFont, // Dùng font cơ bản từ theme
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  h1: {
    fontFamily: fonts.baseFontHeadline,
    fontSize: "6rem", // 96px
    lineHeight: typography1.headingLineHeight,
    fontWeight: 300,
  },
  h2: {
    fontFamily: fonts.baseFontHeadline,
    fontSize: "3.75rem", // 60px
    lineHeight: typography1.headingLineHeight, // 1.2
    letterSpacing: "0.6px",
    fontWeight: 300,
  },
  h3: {
    fontFamily: fonts.baseFontHeadline,
    fontSize: "3rem", // 48px
    lineHeight: typography1.headingLineHeight,
    letterSpacing: "0.48px",
    fontWeight: 300,
  },
  h4: {
    fontFamily: fonts.baseFontHeadline,
    fontSize: "2.125rem", // 34px
    lineHeight: 1.4,
    letterSpacing: "0.68px",
    fontWeight: 300,
  },
  h5: {
    fontFamily: fonts.baseFontHeadline,
    fontSize: "1.5rem", // 24px
    lineHeight: 1.35,
    letterSpacing: "0.48px",
    fontWeight: 300,
  },
  h6: {
    fontFamily: fonts.baseFontHeadline,
    fontSize: "1.5rem", // 24px
    lineHeight: 1.35,
    letterSpacing: "0.48px",
    fontWeight: 700,
  },
  body1: {
    fontFamily: fonts.baseFont,
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: typography1.baseLineHeight,
  },
  body2: {
    fontFamily: fonts.baseFont,
    fontSize: typography1.baseFontSize,
    fontWeight: 300,
    lineHeight: typography1.baseLineHeight,
  },
  button: {
    textTransform: "none",
    fontWeight: 400,
  },
  subtitle1: {
    fontFamily: fonts.baseFontHeadline,
    fontSize: "18px",
    fontWeight: 300,
  },
  caption: {
    fontFamily: fonts.baseFont,
    fontSize: "0.875rem", // 14px cho chữ nhỏ
    fontWeight: 400,
  },
  overline: {
    fontFamily: fonts.baseFont,
    fontSize: "0.875rem",
    fontWeight: 400,
    textTransform: "uppercase",
  },
};

export default typography;
