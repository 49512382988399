import config from "../config";

export const API_URL = process.env.REACT_APP_API_URL as string;
export const API_TIMEOUT = 5000_000;
export const AN_ERROR_TRY_AGAIN = "An error occurred. Please try again!";

export const OPENID_SITE_URL = config.OPENID_SITE_URL as string;

export const MB_SITE_URL = config.METABASE_SITE_URL as string;
export const MB_EMBEDDING_SECRET_KEY =
  config.METABASE_EMBEDDING_SECRET_KEY as string;
