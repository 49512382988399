import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (
  name: string,
  value: string,
  expTime: number
): void => {
  cookies.set(name, value, {
    maxAge: expTime,
    secure: true,
    path: "/",
  });
};

export const getCookie = (name: string): string | undefined => {
  return cookies.get(name);
};

export const removeCookie = (name: string): void => {
  cookies.remove(name);
};

export const removeAllCookies = (): void => {
  const allCookies = cookies.getAll();
  Object.keys(allCookies).forEach((cookieName) => {
    cookies.remove(cookieName, { path: "/" });
    cookies.remove(cookieName, { path: "/auth" });
  });
};
