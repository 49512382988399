import React from "react";
import useAuth from "../../hooks/useAuth";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const { signIn, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    signIn();
  };

  if (isAuthenticated) {
    navigate("/", { replace: true });
    return null;
  }

  return (
    <Box
      className="relative bg-cover bg-center h-screen w-screen flex items-center justify-center"
      style={{ backgroundImage: "url(/static/img/bg_auth_nokia.png)" }}
    >
      <Box className="z-0 absolute top-0 left-0 right-0 bottom-0 bg-black/20" />
      <Box className="z-10 bg-white p-8 rounded-lg shadow-lg flex flex-col items-center text-center max-w-[360px]">
        <Box className="flex items-center mt-8">
          <img
            src="/static/img/brands/logo.svg"
            alt="logo"
            className="w-24 mr-2"
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="h5" className="ml-2">
            Partner Rebate
          </Typography>
        </Box>

        <Typography variant="h5" className="mt-16">
          Log in with your corporate credentials
        </Typography>

        <Button
          onClick={handleLogin}
          variant="outlined"
          className="bg-nokia-blue border border-gray-300 rounded-md px-7 py-2.5 text-base text-white m-8 hover:bg-nokia-blue-hover"
        >
          <Typography>NokiaPartnersSSO</Typography>
        </Button>

        <Link href="/" className="flex items-center text-nokia-blue mb-6">
          <img src="/static/img/ic_home.svg" alt="logo" className="mr-1" />
          Home
        </Link>
      </Box>
      <Typography
        variant="body2"
        className="absolute text-white opacity-80 bottom-4 right-4"
      >
        ©2024 Nokia all rights reserved
      </Typography>
    </Box>
  );
}

export default SignIn;
