import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MapPin } from "react-feather";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { AuthUser } from "../../types/auth";
import PartnerTable from "../components/profile/PartnerTable";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Spacer = styled.div(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

interface UserProps {
  user: AuthUser;
}

function Details({ user }: UserProps) {
  return (
    <Card mb={6}>
      <CardContent>
        <Spacer mb={1} />
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 400 }}>
          Profile Details
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Avatar
            alt={user?.name}
            src={user?.avatar}
            aria-haspopup="true"
            className="hover: cursor-pointer"
          />
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightMedium">{user?.name}</Box>
            <Box fontWeight="fontWeightRegular">{user?.jobTitle}</Box>
          </Typography>
        </Centered>
        <Spacer mb={1} />
      </CardContent>
    </Card>
  );
}

function About({ user }: UserProps) {
  return (
    <Card mb={6}>
      <CardContent>
        <Spacer mb={1} />
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 400 }}>
          About
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <MapPin />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Typography>{user?.location}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <AboutIcon>
              <MailOutlineIcon />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Typography>{user?.email}</Typography>
          </Grid>
        </Grid>
        <Spacer mb={1} />
      </CardContent>
    </Card>
  );
}

function Profile() {
  const { user, partner } = useAuth();
  const partners = partner.map((item) => ({
    ...item,
    id: Number(item.id),
  }));

  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Breadcrumbs aria-label="Breadcrumb" mt={2} className="px-[63px]">
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Typography>My Profile</Typography>
      </Breadcrumbs>

      <Box className="my-6 px-[63px]">
        <Divider />
        <Box className="py-6">
          <Typography variant="h4" fontWeight="bold" display="inline">
            Profile
          </Typography>
        </Box>
        <Grid container spacing={6}>
          {partner.length === 0 ? (
            <Grid item xs={12} lg={12} xl={12}>
              <Details user={user} />
              <About user={user} />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} lg={4} xl={3}>
                <Details user={user} />
                <About user={user} />
              </Grid>
              <Grid item xs={12} lg={8} xl={9}>
                <PartnerTable partners={partners} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Profile;
