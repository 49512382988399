export const environment = {
  local: {
    REDIRECT_URL: "http://localhost:3000",
    OPENID_SITE_URL: "https://open-ecosystem.org",
    OPENID_CLIENT_ID: "nokia_partner_client",
    OPENID_CLIENT_SECRET: "3mXM4Ha7E20E3U8Z4ekpeN8faHPkW9B0",
    METABASE_SITE_URL: "https://metabase.labs-open-ecosystem.org",
    METABASE_EMBEDDING_SECRET_KEY:
      "552c0b5e669b8f5f1baa215bef54401170b49f03a6e64af48a81859bad2c5fd3",
  },
  production: {
    REDIRECT_URL: "https://partners.labs-open-ecosystem.org",
    OPENID_SITE_URL: "https://open-ecosystem.org",
    OPENID_CLIENT_ID: "nokia_partner_client",
    OPENID_CLIENT_SECRET: "3mXM4Ha7E20E3U8Z4ekpeN8faHPkW9B0",
    METABASE_SITE_URL: "https://metabase.labs-open-ecosystem.org",
    METABASE_EMBEDDING_SECRET_KEY:
      "552c0b5e669b8f5f1baa215bef54401170b49f03a6e64af48a81859bad2c5fd3",
  },
};

const env = process.env.REACT_APP_ENVIRONMENT || "production";
// const config = environment[process.env.NODE_ENV] || environment.local;
const config =
  environment[env as keyof typeof environment] || environment.production;

export const oidcConfig = {
  authority: config.OPENID_SITE_URL,
  client_id: config.OPENID_CLIENT_ID,
  client_secret: config.OPENID_CLIENT_SECRET,
  redirect_uri: `${config.REDIRECT_URL}/auth/callback`,
  response_type: "code",
  scope: "openid profile email",
  post_logout_redirect_uri: `${config.REDIRECT_URL}/auth/sign-in`,
};

export default config;
