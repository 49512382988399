// theme.ts

export const breakpoints = {
  mediumScreen: "600px",
  largeScreen: "900px",
};

export const margins = {
  wrapperMarginMob: "20px",
  wrapperMarginDesk: "60px",
};

export const typography1 = {
  fontLight: "NokiaPureHeadline",
  baseFontSize: "14px",
  baseLineHeight: "20px",
  headingLineHeight: 1.2,
};

export const sizes = {
  baseBorderRadius: "3px",
  baseSpacing: "1.5em",
  smallSpacing: "0.75em", // $base-spacing / 2
  baseZIndex: 0,
  inputHeight: "2.8125em",
};

export const colors = {
  blue: "#005AFF",
  blueBlack: "#001135",
  accentBlue: "#0049B4",
  lightBlue: "#90DCE5",
  blue300: "#8ec2ff",
  green: "#37CC73", // mkp-green
  orange: "#F47F31",
  red: "#E23B3B",
  pink: "#FF9AC1",
  pink300: "#faa7f5",
  tableHeader: "#E4FBFF",
  borderColor: "#EBEBEB",
  mustard: "#F7B737",
  bgAccent: "#F9F9F9",
  bgAlternative: "#F3F3F3",
  bgSecondary: "#E4F0FF",
  bgSpinner: "rgba(242, 242, 242, 0.7)",
  mainGray: "#666666", // gray-4
  dateGrey: "#757575",
  gray200: "#F3F3F3",
  gray500: "#CCCCCC",
  white: "#FFFFFF",
  black: "#000000",
  lightInfo: "#E4F0FF",
  lightGreen: "#E4FFEC",
  lightOrange: "#FFF1E4",
  lightRed: "#FFE6E4",
};

export const gradients = {
  linear1: `linear-gradient(90deg, ${colors.lightGreen} 60%, ${colors.white} 90%, ${colors.white} 100%)`,
  linear2: `linear-gradient(90deg, ${colors.lightRed} 60%, ${colors.white} 90%, ${colors.white} 100%)`,
  linear3: `linear-gradient(90deg, ${colors.bgAlternative} 60%, ${colors.white} 90%, ${colors.white} 100%)`,
  linear4: `linear-gradient(90deg, ${colors.lightInfo} 60%, ${colors.white} 90%, ${colors.white} 100%)`,
  linear5: `linear-gradient(90deg, ${colors.lightOrange} 60%, ${colors.white} 90%, ${colors.white} 100%)`,
};

export const fonts = {
  baseFont: "NokiaPureText",
  baseFontHeadline: "NokiaPureHeadline",
};

export const transforms = {
  opposite: "rotate(180deg)",
};

export const transparentColors = {
  whiteTransparent: "rgba(255, 255, 255, 0.4)", // equivalent of transparentize($white, 0.4)
};

export const fontColors = {
  baseFontColor: colors.black,
  actionColor: colors.green,
};

export const borders = {
  baseBorderColor: colors.borderColor,
  baseBorder: `1px solid ${colors.borderColor}`,
};

export const backgroundColors = {
  baseBackgroundColor: colors.white,
  secondaryBackgroundColor: "rgba(235, 235, 235, 0.75)", // approximation for `tint($base-border-color, 75%)`
};

export const forms = {
  formBoxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.06)",
  formBoxShadowFocus: `inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(37, 204, 115, 0.85)`, // approximates adjust-color for action color
};

export const animations = {
  baseDuration: "150ms",
  baseTiming: "ease",
};
